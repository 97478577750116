$font-prefix: ".." !default;
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src:
    local("IBM Plex Sans"),
    local("IBMPlexSans"),
    url("#{$font-prefix}/complete/woff2/IBMPlexSans-Regular.woff2")
      format("woff2"),
    url("#{$font-prefix}/complete/woff/IBMPlexSans-Regular.woff") format("woff");
}

@import "cyrillic";
@import "pi";
@import "latin3";
@import "latin2";
@import "latin1";
@import "greek";
