@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 200;
  src:
    local("IBM Plex Sans ExtLt"),
    local("IBMPlexSans-ExtLt"),
    url("#{$font-prefix}/split/woff2/IBMPlexSans-ExtraLight-Latin2.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
    U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}
