// Styling for the footer
footer {
  padding: 10px 0;
  text-align: center;
  color: fade-out(white, 0.7);
  background-color: $gray-darker;
  p {
    font-size: 12px;
    margin: 0;
  }
  ul {
    margin-bottom: 0;
    li {
      a {
        font-size: 12px;
        color: fade-out(white, 0.7);
        &:hover,
        &:focus,
        &:active,
        &.active {
          text-decoration: none;
        }
      }
    }
  }
}
