@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src:
    local("IBM Plex Sans Medm"),
    local("IBMPlexSans-Medm"),
    url("#{$font-prefix}/split/woff2/IBMPlexSans-Medium-Latin3.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
