$font-prefix: ".." !default;
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 100;
  src:
    local("IBM Plex Sans Thin"),
    local("IBMPlexSans-Thin"),
    url("#{$font-prefix}/complete/woff2/IBMPlexSans-Thin.woff2") format("woff2"),
    url("#{$font-prefix}/complete/woff/IBMPlexSans-Thin.woff") format("woff");
}

@import "cyrillic";
@import "pi";
@import "latin3";
@import "latin2";
@import "latin1";
@import "greek";
