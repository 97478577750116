@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src:
    local("IBM Plex Sans Light Italic"),
    local("IBMPlexSans-LightItalic"),
    url("#{$font-prefix}/split/woff2/IBMPlexSans-LightItalic-Latin3.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
