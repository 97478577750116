@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 100;
  src:
    local("IBM Plex Sans Thin"),
    local("IBMPlexSans-Thin"),
    url("#{$font-prefix}/split/woff2/IBMPlexSans-Thin-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
    U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
    U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
    U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
    U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
    U+ECE0, U+EFCC;
}
