@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  src:
    local("IBM Plex Sans Bold Italic"),
    local("IBMPlexSans-BoldItalic"),
    url("#{$font-prefix}/split/woff2/IBMPlexSans-BoldItalic-Greek.woff2")
      format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}
