/* fonts.scss
 * 
 * Import and apply all `font-family` changes here.
 *
 * Each directory includes an SCSS webfont loadout as
 * well as a static `ttf` directory which can be used
 * in workers to render things natively.
 */

@import "ibm-sans";

body {
  font-family: "IBM Plex Sans", sans-serif !important;
}
