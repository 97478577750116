$font-prefix: ".." !default;
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 500;
  src:
    local("IBM Plex Sans Medm Italic"),
    local("IBMPlexSans-MedmItalic"),
    url("#{$font-prefix}/complete/woff2/IBMPlexSans-MediumItalic.woff2")
      format("woff2"),
    url("#{$font-prefix}/complete/woff/IBMPlexSans-MediumItalic.woff")
      format("woff");
}

@import "cyrillic";
@import "pi";
@import "latin3";
@import "latin2";
@import "latin1";
@import "greek";
