// Styling for the features section
section.features {
  .section-heading {
    margin-bottom: 100px;
    h2 {
      margin-top: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
  .device-container,
  .feature-item {
    max-width: 325px;
    margin: 0 auto;
  }
  .device-container {
    margin-bottom: 100px;
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }
  .feature-item {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    h3 {
      font-size: 30px;
    }
    i {
      font-size: 80px;
      display: block;
      margin-bottom: 15px;
      background: -webkit-linear-gradient(
        to left,
        $theme-secondary,
        $theme-tertiary
      );
      background: linear-gradient(to left, $theme-secondary, $theme-tertiary);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
