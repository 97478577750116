$font-prefix: ".." !default;
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src:
    local("IBM Plex Sans Bold"),
    local("IBMPlexSans-Bold"),
    url("#{$font-prefix}/complete/woff2/IBMPlexSans-Bold.woff2") format("woff2"),
    url("#{$font-prefix}/complete/woff/IBMPlexSans-Bold.woff") format("woff");
}

@import "cyrillic";
@import "pi";
@import "latin3";
@import "latin2";
@import "latin1";
@import "greek";
