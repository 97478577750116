// Styling for the call to action section
section.cta {
  position: relative;
  padding: 250px 0;
  background-image: url("../img/bg-cta.jpg");
  background-position: center;
  @include background-cover;
  .cta-content {
    position: relative;
    z-index: 1;
    h2 {
      font-size: 50px;
      max-width: 450px;
      margin-top: 0;
      margin-bottom: 25px;
      color: white;
    }
    p {
      color: white;
    }
    a {
      color: whitesmoke;
      text-decoration: underline;
    }
    @media (min-width: 768px) {
      h2 {
        font-size: 80px;
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade-out(black, 0.5);
  }
}
