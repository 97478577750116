@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src:
    local("IBM Plex Sans Light"),
    local("IBMPlexSans-Light"),
    url("#{$font-prefix}/split/woff2/IBMPlexSans-Light-Latin3.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
