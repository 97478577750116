// Styling for the masthead
header.masthead {
  position: relative;

  width: 100%;
  min-height: 100vh;
  // padding-bottom: 100px;

  color: white;

  background: url("../img/purple.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .header-content {
    background-color: #000000b3;
    max-width: 500px;
    margin-bottom: 100px;

    h1 {
      font-size: 30px;
    }
    a {
      color: white;
    }
  }
  .device-container {
    max-width: 325px;
    margin-right: auto;
    margin-left: auto;
    .screen img {
      border-radius: 3px;
    }
  }
  @media (max-width: 992px) {
    height: 100vh;
    min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;
    .header-content {
      margin-bottom: 0;

      text-align: left;
      h1 {
        font-size: 50px;
      }
    }
    .device-container {
      max-width: 325px;
    }
  }
}
